import doc2 from '../../assets/documents/bg/Audi-Winter-leaflet_2024_spreads_web.pdf';
import doc1 from '../../assets/documents/bg/vw-winter-leaflets-2024-spreads-web.pdf';

const documents = (t) => [
  {
    name: 'Зимна брошура VW 2024',
    url: doc1,
    type: 'pdf',
  },
  {
    name: 'Зимна брошура Audi 2024',
    url: doc2,
    type: 'pdf',
  },
];

export default documents;
