import doc2 from '../../assets/documents/bg/Резултати от продажбите от 1-9.2024г.xlsx';

const documents = (t) => [
  {
    name: 'Резултати от продажбите от 1-9.2024',
    url: doc2,
    type: 'doc',
  },
];

export default documents;
